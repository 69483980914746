<template>
  <div>
    <el-dialog v-model="dialogVisible" title="新增位置" width="40%" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <div class="firmbox">
        <div><span style="color:red">*</span>安装位置：</div>
        <el-input v-model="name" placeholder="请输入安装位置" maxlength="16" show-word-limit style="width:100%;" />
      </div>
      <div class="firmbox">
        <div><span style="color:red">*</span>安装地址：</div>
        <ls-address @adr-change="getAddress" style="width:100%;"></ls-address>
      </div>
      <div class="firmbox">
        <div></div>
        <el-input v-model="address.detail" placeholder="请输入详细地址" maxlength="50" show-word-limit style="width:100%;" />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel" type="primary" plain>取消</el-button>
          <el-button type="primary" @click="sure">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {addInPosInfo } from '../../../api/api'
import session from '../../../store/store'
import until from '../../../until/until'
export default {
  data() {
    return {
      name: "",
      address: {
          province:'',
          city:'',
          district:'',
          street:'',
          detail:'',
      },
      dialogVisible: true
      
    }
  },
  mounted(){

  },
  methods: {

    //地址改变事件
    getAddress(data) {
      this.address.province = data.selectProvince;
      this.address.city = data.selectCity;
      this.address.district =  data.selectDistrict;
      this.address.street = data.selectStreet
    },

    cancel() {
      this.$emit("cancel")
    },
    close() {
      this.$emit("update")
    },

    sure() {
      if (until.isNULL(this.name)) {
        this.$message.error("请输入名称")
        return;
      }
      if (until.isNULL(this.address.detail)) {
        this.$message.error("请输入完整地址")
        return;
      }

      let address = this.address.province + '#' + this.address.city + '#' + this.address.district + '#' + this.address.street + '#' + this.address.detail;

      if(address == '####') {
        this.$message.error("请选择地址")
        return;
      }
      var param = {
        uuid: session.getLoginInfo().unitUuid,
        name: this.name,
        address:address,
      }
      addInPosInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: '新增成功'});
          this.cancel();
          this.close();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.firmbox {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  div {
    width: 100px;
  }
}
</style>