<template>
  <div class="page">
    <!--标题-->
    <ls-title title="安装位置"></ls-title>
    <!-- 条件框 -->
    <div class="conddiv">
      <el-input v-model="condition" class="w-50 m-2" placeholder="位置搜索" clearable prefix-icon="el-icon-search" style="width:200px;">
      </el-input>
      <el-button type="warning" style="margin-left:5px;" @click="searchPos">搜索</el-button>
      <el-button type="primary" style="float:right;" @click="addPos">新增位置</el-button>
    </div>

    <!-- 表格 -->
    <el-table :data="tableData" border style="width: 100%;border-radius: 10px;" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
      <el-table-column type="index" label="序号"  width="100" label-class-name="tablehead" align="center " />
      <el-table-column prop="name" label="安装位置" label-class-name="tablehead" align="center " />
      <el-table-column prop="address" label="详细地址" label-class-name="tablehead" align="center " >
        <template #default="scope">
          {{ scope.row.address != "" ? scope.row.address.replace(/#/g, "") : "--"}}
        </template>
      </el-table-column>
      <el-table-column label="操作" label-class-name="tablehead" align="center " width="100">
        <template #default="scope"><span style="color:#285df0;cursor: pointer;" @click="del(scope.row)">删除</span></template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
     <div class="pagination">
      <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
    </div>
  </div>
  <template v-if="addShow">
    <InsposAdd @update="update" @cancel="(addShow=false)"></InsposAdd>
  </template>

</template>

<script>
import {getInPosInfos,deleteInPosInfo } from '../../../api/api'
import InsposAdd from './InsposAdd.vue'
import session from '../../../store/store'
export default {
  components: {
    InsposAdd
  },
  data() {
    return {
      emptyText:'加载中',
      addShow: false,//新增页面开关
      condition: "",
      currentPage: 1,
      size: 10,
      total: 0,
      tableData: []
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    init() {
      this.getInPosInfos();
    },
    getInPosInfos() {
      var param = {
        uuid:session.getLoginInfo().unitUuid,
        page:this.currentPage - 1,
        size:this.size,
        condition:this.condition,
      }
      getInPosInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.tableData = res.data.dataInfo;
        }else {
          this.total = 0;
          this.tableData.splice(0, this.tableData.length);
          this.emptyText = "暂无数据"
        }
      })
    },
    
    searchPos() {
      this.getInPosInfos();
    },
    addPos() {
      this.addShow = true;
    },

    update() {
      this.getInPosInfos();
    },
    /**
     * 删除
     */
    del(row) {
      this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var param = {
            unitUuid:session.getLoginInfo().unitUuid,
            uuid:row.uuid,
          }
          deleteInPosInfo(param).then(res => {
            if (res.data.code == 200) {
              this.$message({ message: '删除成功',  type: 'success'});
              this.getInPosInfos();
            } else {
              this.$message.error({ message: res.data.message});
            }
          })

        }).catch(() => {});
    },

    handleSizeChange(val) {
      this.size = val;
      this.getInPosInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getInPosInfos();
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.conddiv {
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 2px 3px 4px var(--el-color-info-light);
  margin-bottom: 20px;
}
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
</style>
<style>
  /* .el-input__prefix {
    top: 5px !important;
  } */
  .tablehead {
    color: white;
  }
  </style>
